import React, { useState } from "react";
import axios from "axios";
import "./App.css";

// For spinner (loading animation)
import { css } from "@emotion/react";
import { RingLoader } from "react-spinners";

// For aesthetic changes
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button, Card, Navbar } from "react-bootstrap";

function App() {

  // This will center the spinner horizontally and vertically in absolute position.
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;
  
  const characters_random = [
    "a lion",
    "a tiger",
    "a bear",
    "a monkey",
    "a rabbit",
    "a dog",
    "a cat",
    "a bird",
    "a fish",
    "a snake",
    "a mouse",
    "a frog",
    "a turtle",
    "a shark",
    "a penguin",
    "a dinosaur",
    "a pirate",
    "a knight",
    "a fairy",
    "a witch",
    "a superhero",
    "a vampire",
    "a zombie",
    "an alien",
    "a mermaid",
    "an astronaut",
    "a detective",
    "a ninja",
    "a cowboy",
    "a king",
    "a queen",
    "a chef",
    "a doctor",
    "a teacher",
    "a farmer",
    "an artist",
    "a musician",
    "an athlete",
    "a magician",
    "a dragon",
    "an elf", 
    "a robot", 
    "a princess", 
    "a spy", 
    "an inventor", 
    "an explorer", 
    "an angel", 
    "a warrior", 
    "an author", 
    "an actor", 
  ];
  
  // Generate a random index between 0 and characters_random.length - 1
  const randomIndex = Math.floor(Math.random() * characters_random.length); 
  
  // Select an element from the array using the random index
  const random_character = characters_random[randomIndex]; 
  
  const random_character_placeholder = "E.g. " + random_character

  const [targetAge, setTargetAge] = useState("5"); // Default set to "5"
  const [theme, setTheme] = useState("funny"); // Default set to "funny"
  const [characters, setCharacters] = useState("");
  const [setting, setSetting] = useState("forest"); // Default set to "forest"
  const [specialObject, setSpecialObject] = useState("magic wand"); // Default set to "magic wand"
  const [story, setStory] = useState("");
  const [storyId, setStoryId] = useState(null); // State variable used with feedback
  const [isLoading, setIsLoading] = useState(false); // New state variable  
  const [loadingMessage, setLoadingMessage] = useState("");
  const [thumbsUpCount, setThumbsUpCount] = useState(0);
  const [thumbsDownCount, setThumbsDownCount] = useState(0);
  const [feedbackGiven, setFeedbackGiven] = useState(false);

  const handleThumbsUpClick = async () => {
    if (!feedbackGiven) {
      try {
        await axios.post('https://storyteller-backend-clff54giha-uw.a.run.app/api/feedback', {
          storyId: storyId,
          feedback: 'up'
        });
        setThumbsUpCount(thumbsUpCount + 1);
        setFeedbackGiven(true);
      } catch (error) {
        console.error(error);
      }
    }
  }
  
  const handleThumbsDownClick = async () => {
    if (!feedbackGiven) {
      try {
        await axios.post('https://storyteller-backend-clff54giha-uw.a.run.app/api/feedback', {
          storyId: storyId,
          feedback: 'down'
        });
        setThumbsDownCount(thumbsDownCount + 1);
        setFeedbackGiven(true);
      } catch (error) {
        console.error(error);
      }
    }
  }  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    setLoadingMessage("Crafting your one-of-a-kind tale...just a moment!"); // Set loading message
    setStory(""); // Clear the previous story

    const payload = {
      target_age: targetAge,
      theme,
      characters: characters || random_character, // If characters state is empty, use random character
      setting,
      special_object: specialObject,
    };
    try {
      // Product backend URL
      const { data } = await axios.post("https://storyteller-backend-clff54giha-uw.a.run.app/api/story", payload); 
      
      // Dev backend URL
      // const { data } = await axios.post("http://localhost:5000/api/story", payload);
      
      // Returned data has story and story_id
      setStory(data.story);
      setStoryId(data.story_id); 
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Stop loading
      // Once story generation is finished
      setLoadingMessage(""); // Clear loading message
    }
  };

  return (
    <div className="App">

      <div className="header">
        <h1 class="display-4">StorySpinner</h1>
        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="StorySpinner Logo" style={{ width: '75%'}} />
      </div>
      
      <form onSubmit={handleSubmit}>

        <Form.Group controlId="ageSelect">
            <Form.Label className="font-weight-bolder text-primary">How old is our brave reader?</Form.Label>
            <Form.Control as="select" value={targetAge} onChange={(e) => setTargetAge(e.target.value)}>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            </Form.Control>
        </Form.Group>

        <Form.Group controlId="themeSelect">
          <Form.Label className="font-weight-bolder text-primary">What's the mood of the story?</Form.Label>
          <Form.Control as="select" value={theme} onChange={(e) => setTheme(e.target.value)}>
            <option value="funny">Funny</option>
            <option value="scary">Scary</option>
            <option value="adventurous">Adventurous</option>
            <option value="educational">Educational</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="characterInput">
          <Form.Label className="font-weight-bolder text-primary">Who's our main character? Could be you!</Form.Label>
          <Form.Control type="text" maxLength="15" placeholder={random_character_placeholder} value={characters} onChange={(e) => setCharacters(e.target.value)} />
        </Form.Group>

        <Form.Group controlId="settingSelect">
          <Form.Label className="font-weight-bolder text-primary">Where does the adventure take place?</Form.Label>
          <Form.Control as="select" value={setting} onChange={(e) => setSetting(e.target.value)}>
            <option value="forest">Forest</option>
            <option value="city">City</option>
            <option value="space">Space</option>
            <option value="school">School</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="objectSelect">
          <Form.Label className="font-weight-bolder text-primary">What magical item is in our story?</Form.Label>
          <Form.Control as="select" value={specialObject} onChange={(e) => setSpecialObject(e.target.value)}>
            <option value="magic wand">Magic Wand</option>
            <option value="lost treasure">Lost Treasure</option>
            <option value="secret letter">Secret Letter</option>
          </Form.Control>
        </Form.Group>

        <Button variant="primary" type="submit">{isLoading ? "Loading..." : "Generate Story"}</Button>

      </form>
      
      <div className="spinner-container">
        <RingLoader color={'#123abc'} loading={isLoading} css={override} size={100} />
        
        {isLoading && <p>{loadingMessage}</p>}
          {story && (
          <Card className="story mb-5">
            <Card.Body>
              {story.split('\n').map((paragraph, index) => (
                <Card.Text key={index} className="lead">{paragraph}</Card.Text>
              ))}

              <Button variant={feedbackGiven ? "success" : "outline-success"} onClick={handleThumbsUpClick} disabled={feedbackGiven} size="lg" className="mr-3">👍{thumbsUpCount}</Button>

              <Button variant={feedbackGiven ? "danger" : "outline-danger"} onClick={handleThumbsDownClick} disabled={feedbackGiven} size="lg">👎{thumbsDownCount}</Button>

            </Card.Body>
          </Card>
        )}

      </div>

      <Navbar fixed="bottom" bg="light">
        <Navbar.Collapse className="justify-content-center">
          <Navbar.Text>
            © 2023 StorySpinner. All rights reserved. | 
            Have feedback or questions? Email me at <a href="mailto:parva.thakkar@gmail.com">parva.thakkar@gmail.com</a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    
    </div>
  );
}

export default App;
